import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { Alert, Box, Breadcrumbs, Button, Link, Grid, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { Artisan, FormState, Profession } from 'model'
import { CertificatArtisan } from 'model/CertificatArtisan'
import { getArtisanById, getCertificats, updateCurrentArtisanAdmin } from 'api/AdminApi'
import type { UpdateArtisanPayload } from 'api/ArtisanApi'
import { boxSx } from 'ui/ComponentsSx'
import ArtisanInfosForm, { ArtisanInfos } from 'forms/ArtisanInfosForm'
import ProfessionInput from 'forms/ProfessionInput'
import CertificatForm from 'pages/artisan/certificats/CertificatForm'

type State = {
  artisan: Artisan | null
  certificatInsurance: CertificatArtisan | null
  certificatsInsuranceOther: CertificatArtisan[]
  isLoading: boolean
}

const ArtisanDetailAdminPage = () => {
  const { artisanId } = useParams()
  const [updates, setUpdates] = useState<UpdateArtisanPayload>({})
  const [formState, setFormState] = useState<FormState>('ready')
  const [state, setState] = useState<State>({
    artisan: null,
    certificatInsurance: null,
    certificatsInsuranceOther: [],
    isLoading: true,
  })

  const updatedArtisan = useMemo(
    () => ({ ...state.artisan, ...updates }),
    [state.artisan, updates],
  )
  const artisanInfos = useMemo(
    () => ({
      nom: updatedArtisan.nom ?? '',
      siret: updatedArtisan.siret ?? '',
      email: updatedArtisan.email ?? '',
      telephone: updatedArtisan.telephone ?? '',
    }),
    [updatedArtisan],
  )

  const infosChange = useCallback(
    (updated: Partial<ArtisanInfos>) =>
      setUpdates(prevUpdates => ({ ...prevUpdates, ...updated })),
    [setUpdates],
  )
  const professionsChange = useCallback(
    (professions: Profession[]) =>
      setUpdates(prevUpdates => ({ ...prevUpdates, professions })),
    [setUpdates],
  )

  useEffect(() => {
    if (artisanId) {
      Promise.all([getArtisanById(artisanId), getCertificats(artisanId)]).then(
        ([artisan, certificats]) => {
          const certificat = certificats.find(c => c.name === 'Assurance') || null
          const certificatsOther = certificats.filter(c => c.name === 'AssuranceAutre')
          setState(st => ({
            ...st,
            artisan,
            certificatInsurance: certificat,
            certificatsInsuranceOther: certificatsOther,
            isLoading: false,
          }))
        },
      )
    } else {
      console.error('No artisan ID provided')
    }
  }, [artisanId, setState])

  const onSubmit = useCallback(() => {
    setFormState('loading')
    if (state.artisan) {
      updateCurrentArtisanAdmin(state.artisan.id, updates)
        .then(updatedArtisan => {
          setFormState('success')
          setState(st => ({ ...st, artisan: updatedArtisan ?? null }))
          setUpdates({})
        })
        .catch(err => setFormState('error'))
    }
  }, [updates, state.artisan, setUpdates])

  if (state.isLoading) {
    return <div>...</div>
  } else if (!state.artisan) {
    return <div>Une erreur est survenue</div>
  } else {
    return (
      <>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginBottom: 3 }}
        >
          {[
            <Link key="1" component={RouterLink} to="/re62/admin/artisans">
              Réparateurs
            </Link>,
            <Typography key="2" color="text.primary">
              Détail
            </Typography>,
          ]}
        </Breadcrumbs>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h2" component="h2" gutterBottom>
            Coordonnées
          </Typography>
          <Box sx={boxSx}>
            <ArtisanInfosForm value={artisanInfos} onChange={infosChange} />
          </Box>
          <Typography variant="h2" component="h2" gutterBottom>
            Compétences
          </Typography>
          <Box sx={boxSx}>
            <ProfessionInput
              value={updatedArtisan.professions ?? []}
              onChange={professionsChange}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              {formState === 'success' && (
                <Alert severity="success">Profil mis à jour</Alert>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="contained"
                onClick={onSubmit}
                color={formState === 'error' ? 'error' : 'primary'}
                disabled={formState === 'loading'}
              >
                Mettre à jour le profil
              </Button>
            </Grid>
          </Grid>
          <Typography variant="h2" component="h2" gutterBottom>
            Certificats
          </Typography>
          <CertificatForm artisanId={state.artisan.id} isAdmin={true} />
        </Box>
      </>
    )
  }
}

export default ArtisanDetailAdminPage
