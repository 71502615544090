import React from 'react'
import { Routes, Route } from 'react-router-dom'

import DossiersAdminList from './DossiersAdminList'
import DossiersAdminDetail from './DossiersAdminDetail'

const DossiersAdminPage = () => (
  <Routes>
    <Route index element={<DossiersAdminList />} />
    <Route path="/:dossierId" element={<DossiersAdminDetail />} />
  </Routes>
)
export default DossiersAdminPage
