import React from 'react'
import { Route, Routes } from 'react-router-dom'

import ArtisansAdminPage from './ArtisansAdminPage'
import ArtisanDetailAdminPage from './ArtisanDetailAdminPage'

const AdministrationArtisans = () => {
  return (
    <Routes>
      <Route index element={<ArtisansAdminPage />} />
      <Route path="/:artisanId" element={<ArtisanDetailAdminPage />} />
    </Routes>
  )
}
export default AdministrationArtisans
