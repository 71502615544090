import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Grid, Link, Typography } from '@mui/material'

import { Dossier } from 'model'
import { DossierRecapContent, ProfessionChips } from 'ui'
import { wrapperSx } from 'ui/ComponentsSx'
import { RecapItem } from 'ui/RecapItem'
import { formatDate } from 'utils/dateFormat'

type TDossiersAdminBlockProps = {
  dossier: Dossier
}

const DossiersAdminBlock = (props: TDossiersAdminBlockProps) => {
  const { dossier } = props
  const { nbContact, createdAt, updatedAt } = dossier

  const nbContactText = !nbContact
    ? 'Aucun artisan positionné'
    : nbContact === 1
      ? '1 artisan positionné'
      : `${nbContact} artisans positionnés`

  return (
    <Box sx={wrapperSx}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={9}
          sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
        >
          <DossierRecapContent dossier={dossier} />
        </Grid>
        <Grid item xs={12} sm={3} textAlign="right">
          {nbContact !== undefined && (
            <Typography component="div" variant="caption" sx={{ mt: 2 }}>
              {nbContactText}
            </Typography>
          )}
          <Link
            underline="none"
            component={RouterLink}
            to={`/re62/admin/dossiers/${dossier.id}`}
          >
            Détails dossier
          </Link>
        </Grid>
        <Grid item xs={12} sx={{ mt: '10px' }}>
          <ProfessionChips
            title="Compétences nécessaires"
            professions={dossier.professions}
            reference={dossier.professions}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, display: 'flex' }}>
          <RecapItem title="Créé le" value={formatDate(createdAt)} inline />
          <RecapItem title="Mis à jour le" value={formatDate(updatedAt)} inline />
        </Grid>
      </Grid>
    </Box>
  )
}
export default DossiersAdminBlock
