import React, { useCallback, useMemo } from 'react'
import { Button, Box, Grid, Typography, Chip, Tooltip } from '@mui/material'

import { Contact, Dossier } from 'model'
import { ExtendedArtisan } from 'model/Artisan'
import { createContact } from 'api/ContactApi'
import { DossierRecapContent, ProfessionChips } from 'ui'
import { wrapperSx } from 'ui/ComponentsSx'
import { RecapItem } from 'ui/RecapItem'
import { formatDate } from 'utils/dateFormat'

type TDossiersArtisanBlockProps = {
  dossier: Dossier
  artisan: ExtendedArtisan
  contact?: Contact
  addContact?: (added: Contact) => void
}

const DossiersArtisanBlock = (props: TDossiersArtisanBlockProps) => {
  const { dossier, artisan, contact, addContact } = props
  const { nbContact, createdAt, updatedAt } = dossier

  const sendContact = useCallback(() => {
    createContact(dossier.id).then(contact => {
      contact && addContact && addContact(contact)
    })
  }, [addContact, dossier])

  const nbContactText = !nbContact
    ? 'Aucun artisan positionné'
    : nbContact === 1
      ? '1 artisan positionné'
      : `${nbContact} artisans positionnés`


  const statusChip = useMemo(() => {
    const isActive = dossier.status === 'Ouvert'
    if (isActive && contact !== undefined) {
      return (
        <Chip
          color="success"
          label="Candidature envoyée"
          sx={{ height: '40px', fontSize: '1rem', fontWeight: '400', p: '0 4px' }}
        />
      )
    } else if (!isActive) {
      return (
        <Chip
          label="Dossier clos"
          sx={{ height: '40px', fontSize: '1rem', fontWeight: '400', p: '0 4px' }}
        />
      )
    } else {
      const disableCandidacy = !artisan.hasValidCertificatAssurance
      const tooltipContent = disableCandidacy
        ? 'Candidature bloquée, certificat(s) en attente(s) de validation'
        : ''
      return (
        <Tooltip title={tooltipContent} disableInteractive>
          <span>
            <Button
              disabled={disableCandidacy}
              variant="contained"
              size="medium"
              onClick={sendContact}
            >
              Candidater
            </Button>
          </span>
        </Tooltip>
      )
    }
  }, [artisan, dossier, contact, sendContact])

  return (
    <Box sx={wrapperSx}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={9}
          sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
        >
          <DossierRecapContent dossier={dossier} artisan={artisan} />
        </Grid>
        <Grid item xs={12} sm={3} textAlign="right">
          {statusChip}
          {nbContact !== undefined && (
            <Typography component="div" variant="body2" sx={{ mt: 2 }}>
              {nbContactText}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mt: '10px' }}>
          <ProfessionChips
            title="Compétences nécessaires"
            professions={dossier.professions}
            reference={artisan?.professions}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, display: 'flex' }}>
          <RecapItem title="Créé le" value={formatDate(createdAt)} inline />
          <RecapItem title="Mis à jour le" value={formatDate(updatedAt)} inline />
        </Grid>
      </Grid>
    </Box>
  )
}
export default DossiersArtisanBlock
