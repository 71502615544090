import React from 'react'
import { Box, Grid } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

import { Artisan } from 'model'
import { ArtisanRecapContent, ProfessionChips } from 'ui'
import { wrapperSx } from 'ui/ComponentsSx'

type TArtisansAdminBlockProps = {
  artisan: Artisan
}

const ArtisansAdminBlock = (props: TArtisansAdminBlockProps) => {
  const { artisan } = props
  const navigate = useNavigate()
  const location = useLocation()

  const handleArtisanClick = () => {
    navigate(`${location.pathname}/${artisan.id}`)
  }

  return (
    <Box sx={{ ...wrapperSx, cursor: 'pointer' }} onClick={handleArtisanClick}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={9}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.2 }}
        >
          <ArtisanRecapContent artisan={artisan} />
        </Grid>
        {artisan.professions.length > 0 && (
          <Grid item xs={12} sx={{ mt: 1.2 }}>
            <ProfessionChips
              title="Compétences"
              professions={artisan.professions}
              reference={artisan.professions}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
export default ArtisansAdminBlock
