import type { ApiError, Artisan, Profession } from 'model'
import { getJson, delJson, postJson, putJson } from './utils'

export type CreateArtisanPayload = {
  nom: string
  siret: string
  email: string
  telephone: string
  password: string
  professions: Profession[]
  recaptcha: string
}

export const createArtisan = (
  payload: CreateArtisanPayload,
): Promise<boolean | ApiError> => postJson(`/re62/api/artisans`, payload)

export type ArtisanLoginPayload = {
  email: string
  password: string
  recaptcha: string
}

export const activateArtisan = (
  artisanId: string,
  artisanToken: string,
): Promise<string | ApiError> =>
  putJson(`/re62/api/artisans/${artisanId}/activate`, { artisanToken })

export const artisanLogin = (payload: ArtisanLoginPayload): Promise<Artisan | ApiError> =>
  postJson(`/re62/artisans/login`, payload)

export type ArtisanPasswordLostPayload = {
  email: string
  recaptcha: string
}

export const artisanPasswordLost = (
  payload: ArtisanPasswordLostPayload,
): Promise<string | ApiError> =>
  postJson(`/re62/api/artisans/passwordLost`, payload.email)

export type ArtisanResetPasswordPayload = {
  password: string
  artisan: string
  key: string
  recaptcha: string
}

export const artisanResetPassword = (
  payload: ArtisanResetPasswordPayload,
): Promise<string | ApiError> => postJson(`/re62/api/artisans/resetPassword`, payload)

export const artisanLogout = (): Promise<void> => delJson(`/re62/artisans/logout`)

export const getCurrentArtisan = (): Promise<Artisan | undefined> =>
  getJson(`/re62/api/artisans/current`)
    .then(data => (data.errors ? undefined : (data as Artisan)))
    .catch(err => undefined)

export type UpdateArtisanPayload = {
  nom?: string
  siret?: string
  email?: string
  telephone?: string
  professions?: Profession[]
}

export const updateCurrentArtisan = (
  payload: UpdateArtisanPayload,
): Promise<Artisan | undefined> => putJson(`/re62/api/artisans/current`, payload)
