import React, { useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'

import type { Contact, Dossier } from 'model'
import { DossierRecapContent, ProfessionChips } from 'ui'

import type { DossierPageView } from './DossierPage'
import ContactBlock from './ContactBlock'

type TDossierRecapViewProps = {
  dossier: Dossier
  contacts: Contact[]
  setView: (v: DossierPageView) => void
  updateContact: (c: Contact) => void
}

const wrapperSx = { border: 1, borderRadius: 2, p: 2, mb: 4 }
const actionSx = { textAlign: 'right', mt: 2 }

const DossierRecapView = (props: TDossierRecapViewProps) => {
  const { dossier, contacts, setView, updateContact } = props
  const editDossier = useCallback(() => setView('edit'), [setView])
  const closeDossier = useCallback(() => setView('close'), [setView])

  const contactsTitle =
    contacts.length === 0
      ? 'Aucun réparateur ne vous a contacté, un peu de patience'
      : contacts.length === 1
        ? 'Un réparateur disponible'
        : `${contacts.length} réparateurs disponibles`

  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        Récapitulatif de ma demande
      </Typography>
      <Box sx={wrapperSx}>
        <DossierRecapContent dossier={dossier} />
        <ProfessionChips
          title="Les travaux concernent"
          professions={dossier.professions}
          reference={dossier.professions}
        />
        <Box sx={actionSx}>
          <Button
            sx={{
              width: { xs: '100%', md: 'auto' },
              mr: { xs: 0, md: 2 },
              mb: { xs: 2, md: 0 },
            }}
            onClick={editDossier}
            variant="outlined"
          >
            Modifier ma demande
          </Button>
          <Button
            sx={{ width: { xs: '100%', md: 'auto' } }}
            onClick={closeDossier}
            variant="contained"
          >
            Clôturer ma demande
          </Button>
        </Box>
      </Box>
      <Typography variant="h6" component="h3" gutterBottom>
        {contactsTitle}
      </Typography>
      {contacts.map(contact => (
        <ContactBlock
          key={contact.id}
          contact={contact}
          updateLocalContact={updateContact}
        />
      ))}
    </>
  )
}
export default DossierRecapView
