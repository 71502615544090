import { ApiError } from 'model'
import { CertificatArtisan } from 'model/CertificatArtisan'
import { delJson, getJson, postMultipart } from './utils'

export type CertificatArtisanFilter = {
  limit?: number
  offset?: number
}

export const listCertificatArtisan = (
  filters: CertificatArtisanFilter,
): Promise<CertificatArtisan[]> =>
  getJson(`/re62/api/artisans/current/certificats`, filters)

export const getCertificatArtisanUrl = (certificatArtisanId: string): string =>
  `/re62/api/artisans/current/certificats/${certificatArtisanId}`

export type UploadCertificatArtisanPayload = {
  file: File
  type: string
}

export const uploadCertificatArtisan = (
  payload: UploadCertificatArtisanPayload,
): Promise<CertificatArtisan | ApiError> =>
  postMultipart(
    `/re62/api/artisans/current/certificats/upload/${payload.type}`,
    payload.file,
  )

export type DeleteCertificatArtisanPayload = {
  certificatArtisanId: string
}

export const deleteCertificatArtisan = (
  payload: DeleteCertificatArtisanPayload,
): Promise<boolean | ApiError> =>
  delJson(`/re62/api/artisans/current/certificats/${payload.certificatArtisanId}`)
