import React from 'react'
import { Box } from '@mui/material'

import { Adresse, Artisan } from 'model'
import { RecapItem } from './RecapItem'

type TArtisanRecapContentProps = {
  artisan: Artisan
}

const printId = (id: string): string => {
  // To have a nicer display, not necessarily unique
  const base = id.split('-')[0]
  return `${base.substring(0, 4)}-${base.substring(4, 8)}`
}

const printAdresse = (adresse: Adresse): string => {
  const { ligne1, ligne2, codePostal, ville, pays } = adresse
  return `${ligne1}\n${ligne2 ? ligne2 + '\n' : ''}${codePostal} ${ville}\n${pays}`
}

export const ArtisanRecapContent = ({ artisan }: TArtisanRecapContentProps) => {
  const { id, nom, siret, email, telephone, adresse } = artisan

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.8 }}>
        <RecapItem title="Artisan n°" value={printId(id)} inline />
        <RecapItem title="Nom" value={nom} inline />
        <RecapItem title="SIRET" value={siret} inline />
        <RecapItem title="Email" value={email} inline />
        <RecapItem title="Téléphone" value={telephone} inline />
      </Box>
      {adresse && <RecapItem title="Adresse" value={printAdresse(adresse)} />}
    </>
  )
}
