import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Grid, Chip, Link } from '@mui/material'

import { RecapItem, ProfessionChips } from 'ui'
import { boxSx } from 'ui/ComponentsSx'
import type { Contact } from 'model'
import { contactStatuslibelles } from 'model'

type TDossierContactBlockProps = {
  contact: Contact
}

const statusSx = { textAlign: 'right' }

export const DossierContactBlock = ({ contact }: TDossierContactBlockProps) => {
  const {
    artisan: { id, nom, adresse, telephone, professions },
    dossier,
    status,
  } = contact

  return (
    <Box sx={boxSx}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <RecapItem title="Nom" value={nom} inline />
          <RecapItem title="Téléphone" value={telephone} inline />
          {adresse && <RecapItem title="Adresse" value={adresse.toString()} inline />}
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Box sx={statusSx}>
            <Chip label={contactStatuslibelles[status]} />
          </Box>
          <Link underline="none" component={RouterLink} to={`/re62/admin/artisans/${id}`}>
            Détails réparateur
          </Link>
        </Grid>
        <Grid item xs={12}>
          <ProfessionChips
            title="Compétences"
            professions={professions}
            reference={dossier.professions}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export default DossierContactBlock
