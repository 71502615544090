import { Artisan } from './Artisan'
import { Dossier } from './Dossier'

export type Contact = {
  id: string
  artisanId: string
  dossierId: string
  createdAt: string
  updatedAt: string
  status: ContactStatus
  artisan: Artisan
  dossier: Dossier
}

export type ContactStatus = 'Initie' | 'Appel' | 'Valide' | 'Annule'
export const contactStatuslibelles: Record<ContactStatus, string> = {
  Initie: "Initié par l'entreprise",
  Appel: 'Entreprise contactée',
  Valide: 'Devis validé',
  Annule: 'Contact annulé',
}
