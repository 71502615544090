import React, { useCallback } from 'react'

import { Grid, TextField } from '@mui/material'

export type ArtisanLoginData = {
  email: string
  password: string
}

type TArtisanLoginFormProps = {
  value: ArtisanLoginData
  onChange: (data: ArtisanLoginData) => void
}

const ArtisanLoginForm = ({ value, onChange }: TArtisanLoginFormProps) => {
  const { email, password } = value
  const setField = useCallback(
    (field: keyof ArtisanLoginData, fieldValue: string) =>
      onChange({ ...value, [field]: fieldValue }),
    [value, onChange],
  )
  const inputChange = useCallback(
    (field: keyof ArtisanLoginData): React.ChangeEventHandler<HTMLInputElement> =>
      ev =>
        setField(field, ev.target.value),
    [setField],
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField
          autoComplete="email"
          name="email"
          required
          fullWidth
          id="email"
          label="Adresse e-mail"
          value={email}
          onChange={inputChange('email')}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="password"
          type="password"
          required
          fullWidth
          id="password"
          label="Mot de passe"
          value={password}
          onChange={inputChange('password')}
        />
      </Grid>
    </Grid>
  )
}
export default ArtisanLoginForm
