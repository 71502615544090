import React, { useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material'
import {
  uploadCertificatArtisan,
  UploadCertificatArtisanPayload,
} from 'api/CertificatArtisanApi'
import { uploadCertificatArtisanAdmin } from 'api/AdminApi'
import { downloadButtonSx } from 'ui/ComponentsSx'

type CertificatUploadFormProps = {
  artisanId: string
  name: string
  refreshCertificats: () => void
  isAdmin: boolean
}

const CertificatUploadForm: React.FC<CertificatUploadFormProps> = ({
  artisanId,
  name,
  refreshCertificats,
  isAdmin,
}) => {
  const handleFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        if (isAdmin) {
          const payload: UploadCertificatArtisanPayload = {
            file: file,
            type: name,
          }
          await uploadCertificatArtisanAdmin(artisanId, payload)
          refreshCertificats()
        } else {
          const payload: UploadCertificatArtisanPayload = {
            file: file,
            type: name,
          }
          await uploadCertificatArtisan(payload)
          refreshCertificats()
        }
      }
    },
    [artisanId, name, refreshCertificats, isAdmin],
  )

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Button variant="contained" component="label" sx={downloadButtonSx}>
        <CloudUploadIcon color="primary" sx={{ fontSize: 24 }} />
        <Typography variant="h5">Télécharger un certificat</Typography>
        <input type="file" hidden onChange={handleFileUpload} />
      </Button>
    </Box>
  )
}

export default CertificatUploadForm
