import React, { useCallback } from 'react'
import { Grid, TextField } from '@mui/material'

export type ArtisanInfos = {
  nom: string
  siret: string
  email: string
  telephone: string
}

type TArtisanInfosFormProps = {
  value: ArtisanInfos
  onChange: (data: Partial<ArtisanInfos>) => void
  errors?: Partial<Record<keyof ArtisanInfos, boolean>>
}

const ArtisanInfosForm = ({ value, onChange, errors }: TArtisanInfosFormProps) => {
  const { nom, siret, email, telephone } = value

  const inputChange = useCallback(
    (field: keyof ArtisanInfos) => (ev: React.ChangeEvent<HTMLInputElement>) =>
      onChange({ [field]: ev.target.value }),
    [onChange],
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="name"
          name="nom"
          required
          fullWidth
          id="nom"
          label="Nom ou raison sociale"
          value={nom}
          onChange={inputChange('nom')}
          error={!!errors?.nom}
          helperText={!!errors?.nom && (nom === '' ? 'Champ requis' : 'Nom invalide')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="siret"
          required
          fullWidth
          id="siret"
          label="Numéro de SIRET"
          value={siret}
          onChange={inputChange('siret')}
          error={!!errors?.siret}
          helperText={
            !!errors?.siret && (siret === '' ? 'Champ requis' : 'Siret invalide')
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="email"
          name="email"
          required
          fullWidth
          id="email"
          label="Adresse e-mail"
          value={email}
          onChange={inputChange('email')}
          error={!!errors?.email}
          helperText={
            !!errors?.email && (email === '' ? 'Champ requis' : 'Email invalide')
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="telephone"
          required
          fullWidth
          id="telephone"
          label="Numéro de téléphone"
          value={telephone}
          onChange={inputChange('telephone')}
          error={!!errors?.telephone}
          helperText={
            !!errors?.telephone &&
            (telephone === '' ? 'Champ requis' : 'Téléphone invalide')
          }
        />
      </Grid>
    </Grid>
  )
}
export default ArtisanInfosForm
