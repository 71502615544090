import React from 'react'
import { Box } from '@mui/material'

import { Artisan, Dossier } from 'model'
import { RecapItem } from './RecapItem'

type TDossierRecapContentProps = {
  dossier: Dossier
  artisan?: Artisan
}

const printId = (id: string): string => {
  // To have a nicer display, not necessarily unique
  const base = id.split('-')[0]
  return `${base.substring(0, 4)}-${base.substring(4, 8)}`
}

export const DossierRecapContent = ({ dossier, artisan }: TDossierRecapContentProps) => {
  const { id, description, logementHabitable, nombrePieces, budget, scoreAmplitude } =
    dossier

  const ampleurText =
    scoreAmplitude === 1 ? 'Faible' : scoreAmplitude === 9 ? 'Elevée' : 'Moyenne'

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '14px' }}>
        <RecapItem title="Dossier n°" value={printId(id)} inline />
        <RecapItem title="Relogement" value={logementHabitable ? 'Non' : 'Oui'} inline />
        <RecapItem title="Pièces endommagées" value={'' + nombrePieces} inline />
        <RecapItem title="Ampleur" value={ampleurText} inline />
      </Box>
      {budget && (
        <RecapItem title="Montant de l'expertise" value={`${budget} € HT`} inline />
      )}
      <RecapItem title="Description des dommages" value={description} />
    </>
  )
}
