export type FormState = 'ready' | 'loading' | 'success' | 'error'

export type Option<A> = A | undefined

export type Updater<T> = (d: T) => T

export type ApiError = { message: string }

// Not very string, but enough for our current needs
export const isError = <T>(data: T | ApiError): data is ApiError =>
  (data as ApiError).message !== undefined
