import React, { useState, useCallback } from 'react'
import { Typography, Box, Grid, Button, Alert } from '@mui/material'

import type { FormState } from 'model'
import { isError } from 'model'
import AdminLoginForm from 'forms/AdminLoginForm'
import type { AdminLoginData } from 'forms/AdminLoginForm'
import { adminLogin, getCurrentUser } from 'api/AdminApi'
import { useSession } from 'stores/SessionProvider'

import firstBanner from 'assets/HP1.png'

const emptyAdminLoginData: AdminLoginData = { user: '', password: '', otp: '' }

const loginSx = {
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 2,
  textAlign: 'center',
}

type TLoginAdminPageState = {
  formState: FormState
  errorMessage?: string
}

const LoginAdminPage = () => {
  const [adminLoginData, setAdminLoginData] =
    useState<AdminLoginData>(emptyAdminLoginData)
  const [state, setState] = useState<TLoginAdminPageState>({ formState: 'ready' })
  const { setAdmin } = useSession()

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    ev => {
      ev.preventDefault()
      const { user, password } = adminLoginData
      if (user === '' || password === '') {
        setState(prev => ({
          ...prev,
          formState: 'error',
          errorMessage: 'Veuillez remplir les champs requis',
        }))
      } else {
        setState(prev => ({ ...prev, formState: 'loading', errorMessage: undefined }))
        adminLogin(adminLoginData).then(res => {
          if (!isError(res)) {
            getCurrentUser().then(res => {
              if (!isError(res)) setAdmin(_ => res)
            })
          } else {
            setState(prev => ({ ...prev, formState: 'error', errorMessage: res.message }))
          }
        })
      }
    },
    [adminLoginData, setAdmin, setState],
  )

  const { formState, errorMessage } = state
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box sx={loginSx}>
          <Typography variant="h1" component="h1" gutterBottom>
            RE62 Admin
          </Typography>
          <form onSubmit={onSubmit}>
            <AdminLoginForm value={adminLoginData} onChange={setAdminLoginData} />
            <Button
              fullWidth
              sx={{ my: 2 }}
              variant="contained"
              type="submit"
              color={formState === 'error' ? 'error' : 'primary'}
              disabled={formState === 'loading'}
            >
              Connexion
            </Button>
          </form>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <img
          src={firstBanner}
          style={{ width: '100%', borderRadius: 24 }}
          alt="First Banner"
        />
      </Grid>
    </Grid>
  )
}
export default LoginAdminPage
