import React, { useRef, useCallback, useState } from 'react'
import { Link as RouterLink, useSearchParams, useMatch } from 'react-router-dom'
import { Typography, Box, Button, Alert, Grid, Breadcrumbs, Link } from '@mui/material'
import ReCAPTCHA from 'react-google-recaptcha'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import ArtisanPasswordForm from 'forms/ArtisanPasswordForm'
import { isError } from 'model'
import type { PasswordInfos } from 'forms/ArtisanPasswordForm'
import { FormState } from 'model'
import { boxSx } from 'ui/ComponentsSx'
import { ArtisanResetPasswordPayload, artisanResetPassword } from 'api/ArtisanApi'

const emptyArtisanPasswords: PasswordInfos = {
  password: '',
  password2: '',
}

type TState = {
  formState: FormState
  errorMessage?: string
  fieldErrors: Record<string, boolean>
}

const ArtisanResetPasswordPage = () => {
  const [artisanPasswordsInfos, setArtisanPasswordsInfos] =
    useState<PasswordInfos>(emptyArtisanPasswords)
  const [state, seState] = useState<TState>({
    formState: 'ready',
    fieldErrors: {
      password: false,
      password2: false,
    },
  })

  const { recaptchaEnabled, recaptchaApiKey } = APP_ENV
  const captchaRef = useRef<ReCAPTCHA>(null)
  const [searchParams] = useSearchParams()
  const artisanMatch = useMatch('/re62/artisan/:artisanId/reset*')

  const artisanId = artisanMatch?.params?.artisanId || ''
  const artisanToken = searchParams.get('token') || ''
  const { formState, errorMessage } = state

  const validateArtisanPasswords = useCallback(
    (infos: PasswordInfos): Record<string, boolean> => {
      return {
        password: !infos.password.trim(),
        password2:
          infos.password !== infos.password2 ||
          infos.password === '' ||
          infos.password2 === '',
      }
    },
    [],
  )

  const setPasswordPartial = useCallback(
    (updates: Partial<PasswordInfos>) => {
      if (state.formState === 'error') {
        setArtisanPasswordsInfos(infos => ({ ...infos, ...updates }))
        const updatedInfos = { ...artisanPasswordsInfos, ...updates }
        const newFieldErrors = validateArtisanPasswords(updatedInfos)
        seState(st => ({ ...st, fieldErrors: newFieldErrors }))
      } else {
        setArtisanPasswordsInfos(infos => ({ ...infos, ...updates }))
        seState(st => ({ ...st, formState: 'ready', errorMessage: undefined }))
      }
    },
    [state.formState, artisanPasswordsInfos, validateArtisanPasswords],
  )

  const onSubmit = useCallback(() => {
    const recaptcha = captchaRef?.current?.getValue() || ''
    const newFieldErrors = validateArtisanPasswords(artisanPasswordsInfos)
    const formHasErrors = Object.values(newFieldErrors).some(error => error)

    if (formHasErrors) {
      seState(st => ({
        ...st,
        formState: 'error',
        errorMessage: 'Veuillez remplir les champs requis.',
        fieldErrors: newFieldErrors,
      }))
    } else if (recaptcha === '' && recaptchaEnabled) {
      seState(st => ({
        ...st,
        formState: 'error',
        errorMessage: 'Veuillez valider le Captcha',
      }))
    } else {
      seState(st => ({ ...st, formState: 'loading', errorMessage: undefined }))
      const payload: ArtisanResetPasswordPayload = {
        password: artisanPasswordsInfos.password,
        artisan: artisanId,
        key: artisanToken,
        recaptcha,
      }
      artisanResetPassword(payload).then(res => {
        if (isError(res)) {
          seState(st => ({ ...st, formState: 'error', errorMessage: res.message }))
        } else {
          seState(st => ({ ...st, formState: 'success' }))
        }
      })
    }
  }, [
    artisanId,
    artisanToken,
    artisanPasswordsInfos,
    validateArtisanPasswords,
    recaptchaEnabled,
    seState,
  ])

  const formContent =
    formState === 'success' ? (
      <Alert severity="success">
        Votre mot de passe a été mis à jour.
        <br />
        Vous pouvez maintenant{' '}
        <Link component={RouterLink} to="/re62/artisan/login">
          vous connecter
        </Link>{' '}
        avec votre nouveau mot de passe.
      </Alert>
    ) : (
      <Box>
        <Box sx={boxSx}>
          <ArtisanPasswordForm
            sx={{ mt: 0 }}
            value={artisanPasswordsInfos}
            onChange={setPasswordPartial}
            errors={state.fieldErrors}
          />
          {recaptchaEnabled && (
            <Box
              sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'flex-end' }}
            >
              <ReCAPTCHA sitekey={recaptchaApiKey} ref={captchaRef} />
            </Box>
          )}
          <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
            <Grid item xs={12} sm={8}>
              {errorMessage && (
                <Alert sx={{ mt: 2 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            sx={{ px: 5, minWidth: { xs: '100%', sm: 'auto' } }}
            variant="contained"
            onClick={onSubmit}
            color={formState === 'error' ? 'error' : 'primary'}
            disabled={formState === 'loading'}
          >
            Mettre à jour
          </Button>
        </Grid>
      </Box>
    )

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginBottom: 3 }}
        >
          {[
            <Link key="1" component={RouterLink} to="/re62">
              Accueil
            </Link>,
            <Typography key="2" color="text.primary">
              Mot de passe oublié
            </Typography>,
          ]}
        </Breadcrumbs>
      </Box>

      <Typography variant="h2" component="h2" gutterBottom>
        Réinitialisation du mot de passe
      </Typography>
      {formContent}
    </>
  )
}
export default ArtisanResetPasswordPage
