import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Box, Typography } from '@mui/material'

import { ExtendedArtisan } from 'model/Artisan'
import { getArtisans, listCertificatArtisanAdmin } from 'api/AdminApi'
import type { ArtisanFilter } from 'api/AdminApi'
import ArtisanAdminBlock from './ArtisanAdminBlock'
import ArtisansAdminFilter from './ArtisansAdminFilter'
import { CertificatArtisanStatus } from 'model/CertificatArtisan'

type State = {
  artisans: ExtendedArtisan[]
  loadingArtisans: boolean
  statusFilter: CertificatArtisanStatus | undefined
}

const emptyFilter: ArtisanFilter = {
  status: 'Actif',
  codesPostaux: [],
  professions: [],
  sortBy: 'createdAt',
  sortOrder: 'DESC',
  limit: 20,
}

const ArtisansAdminPage = () => {
  const [state, setState] = useState<State>({
    artisans: [],
    loadingArtisans: true,
    statusFilter: undefined,
  })

  useEffect(() => {
    getArtisans(emptyFilter).then(async artisans => {
      const extendedArtisans = await Promise.all(
        artisans.map(artisan =>
          listCertificatArtisanAdmin(artisan.id, {}).then(certificats => {
            const hasUploadedCertificatAssurance = certificats.filter(c => c.name === 'Assurance').length > 0
            const hasValidCertificatAssurance = certificats.some(
              certificat => certificat.status === 'Valide' && certificat.name === 'Assurance',
            )

            return { ...artisan, hasUploadedCertificatAssurance, hasValidCertificatAssurance }
          }),
        ),
      )
      setState(st => ({
        ...st,
        artisans: extendedArtisans,
        loadingArtisans: false,
      }))
    })
  }, [setState])

  const filteredArtisans = useMemo(() => {
    if (state.statusFilter === 'Envoye') return state.artisans.filter(a => a.hasUploadedCertificatAssurance)
    else if (state.statusFilter === 'Valide') return state.artisans.filter(a => a.hasValidCertificatAssurance)
    else return state.artisans
  }, [state.artisans, state.statusFilter])

  const onChangeStatus = useCallback(
    (newStatus: CertificatArtisanStatus | undefined) => {
      setState(st => ({
        ...st,
        statusFilter: newStatus,
      }))
    },
    [setState],
  )

  const { artisans, statusFilter, loadingArtisans } = state

  const content = filteredArtisans.map(artisan => (
    <ArtisanAdminBlock key={artisan.id} artisan={artisan} />
  ))

  if (loadingArtisans) return <Typography variant="h3">...</Typography>

  return (
    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ArtisansAdminFilter
        artisans={artisans}
        statusFilter={statusFilter}
        onChangeStatus={onChangeStatus}
      />
      {content}
    </Box>
  )
}
export default ArtisansAdminPage
