import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { useSession } from 'stores/SessionProvider'

import Layout from 'pages/Layout'
import HomePage from 'pages/HomePage'
import ContactPage from 'pages/ContactPage'
import CreationPage from 'pages/dossier/CreationDossierPage'
import DossierPage from 'pages/dossier/DossierPage'
import InscriptionArtisanPage from 'pages/artisan/InscriptionArtisanPage'
import LoginArtisanPage from 'pages/artisan/LoginArtisanPage'
import MentionsLegales from 'pages/MentionsLegales'
import Confidentialite from 'pages/Confidentialite'
import APropos from 'pages/APropos'
import ProfilArtisanPage from 'pages/artisan/ProfileArtisanPage'
import DossiersArtisanPage from 'pages/artisan/dossiers/DossiersArtisanPage'
import ArtisanActivationPage from 'pages/artisan/ArtisanActivationPage'
import Administration from 'pages/administration/Administration'
import ArtisanResetPasswordPage from 'pages/artisan/ArtisanResetPasswordPage'
import ArtisanPasswordLostPage from 'pages/artisan/ArtisanPasswordLostPage'

const Router = () => {
  const { artisan, dossier } = useSession()
  return (
    <Layout>
      <Routes>
        {artisan ? (
          artisan.professions.length > 0 ? (
            <Route index element={<DossiersArtisanPage artisan={artisan} />} />
          ) : (
            <Route index element={<Navigate to="artisan/profile" replace />} />
          )
        ) : (
          <Route index element={<HomePage />} />
        )}
        {artisan && (
          <Route
            path="/artisan/dossiers"
            element={<DossiersArtisanPage artisan={artisan} />}
          />
        )}

        <Route path="/contact" element={<ContactPage />} />

        <Route path="/dossier/creation" element={<CreationPage />} />
        {dossier && (
          <Route path="/dossier/:dossierId" element={<DossierPage dossier={dossier} />} />
        )}

        <Route path="/artisan/inscription" element={<InscriptionArtisanPage />} />
        <Route path="/artisan/login" element={<LoginArtisanPage />} />
        <Route path="/artisan/passwordLost" element={<ArtisanPasswordLostPage />} />
        {artisan && (
          <Route
            path="/artisan/profile"
            element={<ProfilArtisanPage artisan={artisan} />}
          />
        )}
        <Route path="/artisan/:artisanId" element={<ArtisanActivationPage />} />
        <Route path="/artisan/:artisanId/reset" element={<ArtisanResetPasswordPage />} />

        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/politique-confidentialite" element={<Confidentialite />} />
        <Route path="/a-propos" element={<APropos />} />
        <Route path="/admin/*" element={<Administration />} />
      </Routes>
    </Layout>
  )
}
export default Router
