import React, { useCallback } from 'react'
import { Box, Typography, Tooltip, IconButton, Chip } from '@mui/material'
import { GetApp as GetAppIcon, DeleteForever, Close, Done } from '@mui/icons-material'
import {
  getCertificatArtisanUrl,
  deleteCertificatArtisan,
  DeleteCertificatArtisanPayload,
} from 'api/CertificatArtisanApi'
import {
  deleteCertificatArtisan as deleteCertificatArtisanAdmin,
  DeleteCertificatArtisanPayload as DeleteCertificatArtisanPayloadAdmin,
  updateCertificatArtisan as updateCertificatArtisanAdmin,
  UpdateCertificatArtisanPayload as UpdateCertificatArtisanPayloadAdmin,
} from 'api/AdminApi'
import type { CertificatArtisan } from 'model/CertificatArtisan'
import { previewBoxSx, buttonSx } from 'ui/ComponentsSx'

type CertificatItemProps = {
  certificat: CertificatArtisan
  refreshCertificats: () => void
  isAdmin: boolean
}

const CertificatItem: React.FC<CertificatItemProps> = ({
  certificat,
  refreshCertificats,
  isAdmin,
}) => {
  const isCertificatValide = certificat.status === 'Valide'
  const isCertificatRefuse = certificat.status === 'Refus'

  const handleUpdateCertificat = (status: string) => {
    if (isAdmin) {
      const payload: UpdateCertificatArtisanPayloadAdmin = {
        filename: certificat.filename,
        name: certificat.name,
        status: status,
      }
      updateCertificatArtisanAdmin(certificat.artisanId, certificat.id, payload).then(
        () => refreshCertificats(),
      )
    }
  }

  const handleRemoveFile = useCallback(() => {
    if (certificat) {
      if (isAdmin) {
        const payload: DeleteCertificatArtisanPayloadAdmin = {
          artisanId: certificat.artisanId,
          certificatArtisanId: certificat.id,
        }
        deleteCertificatArtisanAdmin(payload).then(() => refreshCertificats())
      } else {
        const payload: DeleteCertificatArtisanPayload = {
          certificatArtisanId: certificat.id,
        }
        deleteCertificatArtisan(payload).then(() => refreshCertificats())
      }
    }
  }, [certificat, isAdmin, refreshCertificats])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1em' }}
      >
        <Tooltip title={certificat.filename}>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'left',
              width: 230,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {certificat.filename}
          </Typography>
        </Tooltip>
        <Chip
          sx={{
            p: 0.5,
            height: '100%',
            border: getBorderFromStatus(certificat.status),
            backgroundColor: '#FFF',
          }}
          label={
            <>
              <Typography component="span" variant="body2" sx={{ color: '#0E0E0E' }}>
                {getWordingFromStatus(certificat.status)}
              </Typography>
            </>
          }
        />
      </Box>
      <Box sx={previewBoxSx}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2em',
          }}
        >
          <IconButton
            sx={buttonSx}
            aria-label="download"
            href={getCertificatArtisanUrl(certificat.id)}
          >
            <Typography variant="body1" color="primary">
              Télécharger
            </Typography>
            <GetAppIcon color="primary" />
          </IconButton>

          {isAdmin && (
            <>
              <IconButton
                sx={buttonSx}
                aria-label="validate"
                onClick={() => handleUpdateCertificat('Valide')}
                disabled={isCertificatValide}
              >
                <Typography
                  variant="body1"
                  color={isCertificatValide ? 'text.disabled' : 'primary'}
                >
                  Valider
                </Typography>
                <Done sx={{ color: isCertificatValide ? 'grey' : 'green' }} />
              </IconButton>
              <IconButton
                sx={buttonSx}
                aria-label="delete"
                onClick={() => handleUpdateCertificat('Refus')}
                disabled={isCertificatRefuse}
              >
                <Typography
                  variant="body1"
                  color={isCertificatRefuse ? 'text.disabled' : 'primary'}
                >
                  Refuser
                </Typography>
                <Close color={isCertificatRefuse ? 'disabled' : 'error'} />
              </IconButton>
            </>
          )}

          <IconButton sx={buttonSx} aria-label="delete" onClick={handleRemoveFile}>
            <Typography variant="body1" color="primary">
              Supprimer
            </Typography>
            <DeleteForever color="error" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

const getWordingFromStatus = (status: string) => {
  switch (status) {
    case 'Refus':
      return 'Refusé'
    case 'Valide':
      return 'Validé'
    default:
      return 'En attente de validation'
  }
}

const getBorderFromStatus = (status: string) => {
  switch (status) {
    case 'Refus':
      return '2px solid #E84855'
    case 'Valide':
      return '2px solid green'
    default:
      return '2px solid #79C8E6'
  }
}

export default CertificatItem
