import React, { useState, useCallback, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  Alert,
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from '@mui/material'

import { contactUs, type ContactUsPayload } from 'api/ContactApi'
import { isError, type FormState } from 'model'
import { boxSx } from 'ui/ComponentsSx'

const emptyContact: ContactUsPayload = {
  isArtisan: false,
  email: '',
  sujet: '',
  message: '',
  recaptcha: '',
}

const radioSx = {
  borderRadius: 6,
  ml: 0,
  mr: 2,
  pr: 2,
}

type TContactPageState = {
  state: FormState
  errorMessage?: string
}

const ContactPage = () => {
  const [payload, setPayload] = useState<ContactUsPayload>(emptyContact)
  const [{ state, errorMessage }, setState] = useState<TContactPageState>({
    state: 'ready',
  })
  const { recaptchaEnabled, recaptchaApiKey } = APP_ENV
  const captchaRef = useRef<ReCAPTCHA>(null)

  const isArtisanChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setPayload(prev => ({ ...prev, isArtisan: ev.target.value === 'true' }))
    },
    [setPayload],
  )
  const inputChange = useCallback(
    (field: 'email' | 'sujet' | 'message') =>
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        setPayload(prev => ({ ...prev, [field]: ev.target.value }))
        setState(st => ({ ...st, state: 'ready' }))
      },
    [setState, setPayload],
  )

  const onSubmit = useCallback(() => {
    const { email, sujet, message } = payload
    const recaptcha = captchaRef?.current?.getValue() || ''
    if (email === '' || sujet === '' || message === '') {
      setState(st => ({
        ...st,
        state: 'error',
        errorMessage: 'Tous les champs sont requis',
      }))
    } else if (recaptcha === '' && recaptchaEnabled) {
      setState(prev => ({
        ...prev,
        formState: 'error',
        errorMessage: 'Veuillez valider le Captcha',
      }))
    } else {
      setState(st => ({ ...st, state: 'loading' }))
      contactUs({ ...payload, recaptcha }).then(res => {
        if (isError(res)) {
          setState(st => ({ ...st, state: 'error', errorMessage: res.message }))
        } else {
          setState(st => ({ ...st, state: 'success', errorMessage: undefined }))
        }
      })
    }
  }, [payload, setState, recaptchaEnabled])
  const { isArtisan, email, sujet, message } = payload

  const formContent = (
    <Box>
      <Box sx={boxSx}>
        <FormControl sx={{ mb: 2 }}>
          <RadioGroup
            row
            name="isArtisan"
            value={isArtisan ? 'true' : 'false'}
            onChange={isArtisanChange}
          >
            <FormControlLabel
              sx={radioSx}
              value="false"
              control={<Radio sx={{ pr: 1 }} />}
              label="Je suis sinistré"
            />
            <FormControlLabel
              sx={radioSx}
              value="true"
              control={<Radio sx={{ pr: 1 }} />}
              label="Je suis artisan"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          sx={{ mb: 2 }}
          name="email"
          fullWidth
          id="email"
          label="Adresse e-mail*"
          onChange={inputChange('email')}
          value={email}
        />
        <TextField
          sx={{ mb: 2 }}
          name="sujet"
          fullWidth
          id="sujet"
          label="Sujet*"
          onChange={inputChange('sujet')}
          value={sujet}
        />
        <TextField
          sx={{ mb: 0 }}
          name="message"
          fullWidth
          multiline
          id="message"
          label="Demande*"
          onChange={inputChange('message')}
          value={message}
          inputProps={{ minRows: 3 }}
        />
        {recaptchaEnabled && (
          <Box sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <ReCAPTCHA sitekey={recaptchaApiKey} ref={captchaRef} />
          </Box>
        )}
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          disabled={state === 'loading'}
          color={state === 'error' ? 'error' : 'primary'}
          onClick={onSubmit}
        >
          Envoyer la demande
        </Button>
        {errorMessage && (
          <Alert sx={{ mt: 2 }} severity="error">
            {errorMessage}
          </Alert>
        )}
      </Box>
    </Box>
  )

  const mainContent =
    state === 'success' ? (
      <Alert severity="success" sx={{ mt: 2 }}>
        Votre message nous a bien été transmis
      </Alert>
    ) : (
      formContent
    )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h2" component="h2">
          Demande de contact
        </Typography>
        <Typography component="span" variant="body1">
          Nous vous répondrons dans les plus brefs délais
        </Typography>
        {mainContent}
      </Grid>
    </Grid>
  )
}
export default ContactPage
