import React from 'react'

import { Link as RouterLink } from 'react-router-dom'
import { Typography, Grid, Breadcrumbs, Link } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const MentionsLegales = () => (
  <Grid container spacing={8}>
    <Grid item xs={12}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ marginBottom: 6 }}
      >
        {[
          <Link key="1" component={RouterLink} to="/re62">
            Accueil
          </Link>,
          <Typography key="2" color="text.primary">
            Mentions Légales
          </Typography>,
        ]}
      </Breadcrumbs>

      <Typography variant="h2" component="h2" gutterBottom>
        Éditeur du site :
      </Typography>
      <Typography variant="body1" gutterBottom>
        SARETEC DEVELOPPEMENT, société anonyme à conseil d’administration
        <br />
        Au capital social de 1 356 080,00 euros <br />
        Situé au 9-11 Rue Georges Enesco 94000 Créteil
        <br />
        Inscrite au Registre du commerce et des sociétés de Créteil sous le numéro 352 603
        575 <br />
        Numéro de téléphone : 01 49 56 84 00 <br />
        Adresse de courrier électronique : contact@reconstruireensemble62.fr
        <br />
        Directeur de la publication :<br />
        Monsieur Jean-Vincent RAYMONDIS, Président du Conseil d’administration
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h2" component="h2" gutterBottom>
        Création de site internet :
      </Typography>
      <Typography variant="body1" gutterBottom>
        Keywiiz by Saretec
      </Typography>
    </Grid>
  </Grid>
)
export default MentionsLegales
