import type { ApiError, Profession } from 'model'
import { Admin } from 'model/Admin'
import { CertificatArtisan } from 'model/CertificatArtisan'
import { delJson, getJson, postJson, postMultipart, putJson } from './utils'
import type { Artisan, ArtisanStatus } from 'model'
import {
  CertificatArtisanFilter,
  UploadCertificatArtisanPayload,
} from './CertificatArtisanApi'

export type AdminLoginPayload = {
  user: string
  password: string
  otp: string
}

export const adminLogin = (payload: AdminLoginPayload): Promise<string | ApiError> =>
  postJson(`/login`, { ...payload, rememberMe: true })

export const adminLogout = (): Promise<string> => postJson(`/logout`, {})

export const getCurrentUser = (): Promise<Admin | ApiError> =>
  getJson(`/api/users/current`)

export type ArtisanFilter = {
  codesPostaux: string[]
  professions: string[]
  status?: ArtisanStatus
  sortBy: 'createdAt'
  sortOrder: 'ASC' | 'DESC'
  limit?: number
  offset?: number
}

export const getArtisans = (filters: ArtisanFilter): Promise<Artisan[]> =>
  getJson('/re62/api/artisans', filters)

export type UpdateArtisanPayloadAdmin = {
  nom?: string
  siret?: string
  email?: string
  telephone?: string
  professions?: Profession[]
}

export const updateCurrentArtisanAdmin = (
  artisanId: string,
  payload: UpdateArtisanPayloadAdmin,
): Promise<Artisan | undefined> => putJson(`/re62/api/artisans/${artisanId}`, payload)

export const getArtisanById = (artisanId: string): Promise<Artisan | null> =>
  getJson(`/re62/api/artisans/${artisanId}`)

export const listCertificatArtisanAdmin = (
  artisanId: string,
  filter: CertificatArtisanFilter,
): Promise<CertificatArtisan[]> =>
  getJson(`/re62/api/artisans/${artisanId}/certificats`, filter)

export const getCertificatArtisanUrlAdmin = (
  artisanId: string,
  certificatArtisanId: string,
): string => `/re62/api/artisans/${artisanId}/certificats/${certificatArtisanId}`

export const uploadCertificatArtisanAdmin = (
  artisanId: string,
  payload: UploadCertificatArtisanPayload,
): Promise<CertificatArtisan | ApiError> =>
  postMultipart(
    `/re62/api/artisans/${artisanId}/certificats/upload/${payload.type}`,
    payload.file,
  )

export const getCertificats = (artisanId: string): Promise<CertificatArtisan[]> =>
  getJson(`/re62/api/artisans/${artisanId}/certificats`)

export type UpdateCertificatArtisanPayload = {
  filename: string
  name: string
  status: string
}

export const updateCertificatArtisan = (
  artisanId: string,
  certificatArtisanId: string,
  payload: UpdateCertificatArtisanPayload,
): Promise<CertificatArtisan | ApiError> =>
  putJson(`/re62/api/artisans/${artisanId}/certificats/${certificatArtisanId}`, payload)

export type DeleteCertificatArtisanPayload = {
  artisanId: string
  certificatArtisanId: string
}

export const deleteCertificatArtisan = (
  payload: DeleteCertificatArtisanPayload,
): Promise<boolean | ApiError> =>
  delJson(
    `/re62/api/artisans/${payload.artisanId}/certificats/${payload.certificatArtisanId}`,
  )
