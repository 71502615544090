import React, { useCallback, useMemo } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

import { Dossier, DossierStatus } from 'model'

type Props = {
  dossiers: Dossier[]
  statusFilter: DossierStatus | undefined
  onChangeStatus: (status: DossierStatus | undefined) => void
}

const DossiersAdminFilter = ({ dossiers, statusFilter, onChangeStatus }: Props) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const status =
        event.target.value && event.target.value !== ''
          ? (event.target.value as DossierStatus)
          : undefined

      onChangeStatus(status)
    },
    [onChangeStatus],
  )

  const statusFilterValue =
    statusFilter !== undefined && statusFilter.toString() !== '' ? statusFilter : ''

  const dossierCounts = useMemo(() => {
    return {
      Attentes: dossiers.filter(d => d.status === 'Attente').length,
      Ouverts: dossiers.filter(d => d.status === 'Ouvert').length,
      Resolus: dossiers.filter(d => d.status === 'Resolu').length,
    }
  }, [dossiers])

  return (
    <FormControl>
      <FormLabel id="status-radio-buttons-group">Status</FormLabel>
      <RadioGroup
        aria-labelledby="status-radio-buttons-group"
        name="status-radio-buttons-group"
        row
        value={statusFilterValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value=""
          control={<Radio />}
          label={`Tous (${dossiers.length})`}
        />
        <FormControlLabel
          value="Attente"
          control={<Radio />}
          label={`En attentes (${dossierCounts.Attentes})`}
          disabled={dossierCounts.Attentes === 0}
        />
        <FormControlLabel
          value="Ouvert"
          control={<Radio />}
          label={`Ouverts (${dossierCounts.Ouverts})`}
          disabled={dossierCounts.Ouverts === 0}
        />
        <FormControlLabel
          value="Resolu"
          control={<Radio />}
          label={`Résolus (${dossierCounts.Resolus})`}
          disabled={dossierCounts.Resolus === 0}
        />
      </RadioGroup>
    </FormControl>
  )
}
export default DossiersAdminFilter
