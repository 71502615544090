import React from 'react'

import { Typography } from '@mui/material'

const APropos = () => (
  <>
    <Typography variant="h4" component="h1" gutterBottom>
      A props de nous
    </Typography>
    TODO
  </>
)
export default APropos
