import React, { useState, useCallback, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { isError, type FormState } from 'model'
import { Typography, Box, Breadcrumbs, Link, Button, Grid, Alert } from '@mui/material'
import ReCAPTCHA from 'react-google-recaptcha'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import ArtisanInfosForm from 'forms/ArtisanInfosForm'
import type { ArtisanInfos } from 'forms/ArtisanInfosForm'
import ArtisanPasswordForm from 'forms/ArtisanPasswordForm'
import type { PasswordInfos } from 'forms/ArtisanPasswordForm'
import { createArtisan } from 'api/ArtisanApi'
import type { CreateArtisanPayload } from 'api/ArtisanApi'
import { boxSx } from 'ui/ComponentsSx'

const emptyArtisanInfos: ArtisanInfos & PasswordInfos = {
  nom: '',
  siret: '',
  email: '',
  telephone: '',
  password: '',
  password2: '',
}

type TState = {
  formState: FormState
  errorMessage?: string
  fieldErrors: Record<string, boolean>
}

const InscriptionArtisanPage = () => {
  const [artisanInfos, setArtisanInfos] = useState<ArtisanInfos & PasswordInfos>(
    emptyArtisanInfos,
  )
  const [state, seState] = useState<TState>({
    formState: 'ready',
    fieldErrors: {
      nom: false,
      siret: false,
      email: false,
      telephone: false,
      password: false,
      password2: false,
    },
  })

  const validateArtisanInfos = useCallback(
    (infos: ArtisanInfos & PasswordInfos): Record<string, boolean> => {
      const siretRegex = /^(\d{3} ){4}\d{5}$|^\d{14}$/
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const phoneRegex = /^0\d{9}$/

      return {
        nom: !infos.nom.trim(),
        siret: !siretRegex.test(infos.siret.replace(/\s+/g, '')),
        email: !emailRegex.test(infos.email),
        telephone: !phoneRegex.test(infos.telephone.replace(/\s+/g, '')),
        password: !infos.password.trim(),
        password2:
          infos.password !== infos.password2 ||
          infos.password === '' ||
          infos.password2 === '',
      }
    },
    [],
  )

  const { formState, errorMessage } = state
  const setArtisanPartial = useCallback(
    (updates: Partial<ArtisanInfos>) => {
      if (state.formState === 'error') {
        setArtisanInfos(infos => ({ ...infos, ...updates }))
        const updatedInfos = { ...artisanInfos, ...updates }
        const newFieldErrors = validateArtisanInfos(updatedInfos)
        seState(st => ({ ...st, fieldErrors: newFieldErrors }))
      } else {
        setArtisanInfos(infos => ({ ...infos, ...updates }))
        seState(st => ({ ...st, formState: 'ready', errorMessage: undefined }))
      }
    },
    [state.formState, artisanInfos, validateArtisanInfos],
  )

  const setPasswordPartial = useCallback(
    (updates: Partial<PasswordInfos>) => {
      if (state.formState === 'error') {
        setArtisanInfos(infos => ({ ...infos, ...updates }))
        const updatedInfos = { ...artisanInfos, ...updates }
        const newFieldErrors = validateArtisanInfos(updatedInfos)
        seState(st => ({ ...st, fieldErrors: newFieldErrors }))
      } else {
        setArtisanInfos(infos => ({ ...infos, ...updates }))
        seState(st => ({ ...st, formState: 'ready', errorMessage: undefined }))
      }
    },
    [state.formState, artisanInfos, validateArtisanInfos],
  )

  const { recaptchaEnabled, recaptchaApiKey } = APP_ENV
  const captchaRef = useRef<ReCAPTCHA>(null)

  const onSubmit = useCallback(() => {
    const recaptcha = captchaRef?.current?.getValue() || ''
    const newFieldErrors = validateArtisanInfos(artisanInfos)
    const formHasErrors = Object.values(newFieldErrors).some(error => error)

    if (formHasErrors) {
      seState(st => ({
        ...st,
        formState: 'error',
        errorMessage: 'Veuillez remplir les champs requis.',
        fieldErrors: newFieldErrors,
      }))
    } else if (recaptcha === '' && recaptchaEnabled) {
      seState(st => ({
        ...st,
        formState: 'error',
        errorMessage: 'Veuillez valider le Captcha',
      }))
    } else {
      seState(st => ({ ...st, formState: 'loading', errorMessage: undefined }))
      const payload: CreateArtisanPayload = {
        ...artisanInfos,
        professions: [],
        recaptcha,
      }
      createArtisan(payload).then(res => {
        if (isError(res)) {
          seState(st => ({ ...st, formState: 'error', errorMessage: res.message }))
        } else {
          seState(st => ({ ...st, formState: 'success' }))
        }
      })
    }
  }, [artisanInfos, validateArtisanInfos, recaptchaEnabled, seState])

  const formContent =
    formState === 'success' ? (
      <Alert severity="success">
        Votre compte a été créé. Veuillez consulter vos emails pour finaliser votre
        inscription
      </Alert>
    ) : (
      <Box>
        <Box sx={boxSx}>
          <ArtisanInfosForm
            value={artisanInfos}
            onChange={setArtisanPartial}
            errors={state.fieldErrors}
          />
          <ArtisanPasswordForm
            sx={{ mt: 0 }}
            value={artisanInfos}
            onChange={setPasswordPartial}
            errors={state.fieldErrors}
          />
          {recaptchaEnabled && (
            <Box
              sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'flex-end' }}
            >
              <ReCAPTCHA sitekey={recaptchaApiKey} ref={captchaRef} />
            </Box>
          )}
          <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
            <Grid item xs={12} sm={8}>
              {errorMessage && (
                <Alert sx={{ mt: 2 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            sx={{ px: 5, minWidth: { xs: '100%', sm: 'auto' } }}
            variant="contained"
            onClick={onSubmit}
            color={formState === 'error' ? 'error' : 'primary'}
            disabled={formState === 'loading'}
          >
            Inscription
          </Button>
        </Grid>
      </Box>
    )

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginBottom: 3 }}
        >
          {[
            <Link key="1" component={RouterLink} to="/re62">
              Accueil
            </Link>,
            <Typography key="2" color="text.primary">
              Inscription répérateur
            </Typography>,
          ]}
        </Breadcrumbs>

        <Typography variant="subtitle1" component="span" gutterBottom>
          Déjà un compte ?{' '}
          <Link variant="subtitle1" component={RouterLink} to="/re62/artisan/login">
            Connexion
          </Link>
        </Typography>
      </Box>

      <Typography variant="h2" component="h2" gutterBottom>
        Mes coordonnées
      </Typography>
      {formContent}
    </>
  )
}
export default InscriptionArtisanPage
