import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 24,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { color: '#292929' },
      },
    },
  },
  palette: {
    background: {
      default: '#F8FBFF',
    },
    primary: {
      main: '#102E46',
    },
    secondary: {
      main: '#79C8E6',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    h1: { fontSize: '1.625rem', fontWeight: 400, margin: '20px 0', color: '#102E36' },
    h2: { fontSize: '1.375rem', fontWeight: 500, margin: '20px 0', color: '#102E36' },
    h3: { fontSize: '1.25rem', fontWeight: 500, margin: '15px 0', color: '#102E36' },
    h4: { fontSize: '1.125rem', color: '#102E36' },
    h5: { fontSize: '1rem', color: '#102E36' },
    h6: { fontSize: '0.875rem', fontWeight: '500', color: '#102E36' },
    button: { fontSize: '1rem', textTransform: 'none', fontWeight: '400' },
    body1: { fontSize: '1rem', color: '#292929' },
    body2: { fontSize: '0.875rem', fontWeight: 'normal', color: '#102E36' },
  },
})

export default theme
