import React, { useState, useMemo, useCallback } from 'react'
import { Typography, Box, Button, Alert } from '@mui/material'

import type { Dossier, FormState, Updater } from 'model'
import DossierInfosForm from 'forms/DossierInfosForm'
import type { DossierInfos } from 'forms/DossierInfosForm'
import DossierDommagesForm from 'forms/DossierDommagesForm'
import type { DossierDommages } from 'forms/DossierDommagesForm'
import { updateDossier, useDossierParameters } from 'api/DossierApi'
import type { UpdateDossierPayload } from 'api/DossierApi'
import { boxSx } from 'ui/ComponentsSx'

import type { DossierPageView } from './DossierPage'

type TDossierEditViewProps = {
  dossier: Dossier
  setDossier: (fn: Updater<Dossier | undefined>) => void
  setView?: (v: DossierPageView) => void
  coordsTitle?: string
  dommagesTitle?: string
}

const actionSx = { textAlign: 'right', mt: 2 }

const DossierEditView = (props: TDossierEditViewProps) => {
  const {
    dossier,
    setDossier,
    setView,
    coordsTitle = 'Mes coordonnées',
    dommagesTitle = 'Mes dommages',
  } = props
  const [formState, setFormState] = useState<FormState>('ready')
  const [updates, setUpdates] = useState<UpdateDossierPayload>({})

  const { dossierKey } = useDossierParameters()

  const updatedDossier = useMemo(
    () => ({
      ...dossier,
      ...updates,
      adresse: updates.codePostal
        ? { ...dossier.adresse, codePostal: updates.codePostal }
        : dossier.adresse,
    }),
    [dossier, updates],
  )

  const infos: DossierInfos = useMemo(
    () => ({
      nom: updatedDossier.nom,
      email: updatedDossier.email,
      telephone: updatedDossier.telephone,
      codePostal: updatedDossier.adresse.codePostal,
    }),
    [updatedDossier],
  )

  const dommages: DossierDommages = useMemo(
    () => ({
      professions: updatedDossier.professions,
      description: updatedDossier.description,
      budget: updatedDossier.budget,
      logementHabitable: updatedDossier.logementHabitable,
      nombrePieces: updatedDossier.nombrePieces,
      scoreAmplitude: updatedDossier.scoreAmplitude,
    }),
    [updatedDossier],
  )

  const onSubmit = useCallback(() => {
    setFormState('loading')
    updateDossier(dossier.id, dossierKey, updates)
      .then(updatedDossier => {
        setFormState('success')
        setDossier(() => updatedDossier)
        setUpdates({})
        setView && setView('contacts')
      })
      .catch(err => setFormState('error'))
  }, [setFormState, setDossier, updates, setUpdates, setView, dossier, dossierKey])

  const onCancel = useCallback(() => {
    setUpdates({})
    setView && setView('contacts')
  }, [setUpdates, setView])

  const infosChange = useCallback(
    (updated: Partial<DossierInfos>) => {
      setUpdates(prevUpdates => ({ ...prevUpdates, ...updated }))
      setFormState('ready')
    },
    [setUpdates, setFormState],
  )

  const dommagesChange = useCallback(
    (updated: Partial<DossierDommages>) => {
      setUpdates(prevUpdates => ({ ...prevUpdates, ...updated }))
      setFormState('ready')
    },
    [setUpdates, setFormState],
  )

  return (
    <>
      <Typography variant="h2" component="h2" gutterBottom>
        {coordsTitle}
      </Typography>
      <Box sx={boxSx}>
        <DossierInfosForm value={infos} onChange={infosChange} />
      </Box>

      <Typography variant="h2" component="h2" gutterBottom>
        {dommagesTitle}
      </Typography>
      <Box sx={boxSx}>
        <DossierDommagesForm value={dommages} onChange={dommagesChange} />
      </Box>
      {formState === 'success' && <Alert severity="success">Demande mise à jour</Alert>}
      <Box sx={actionSx}>
        <Button onClick={onCancel} disabled={formState === 'loading'} sx={{ mr: 2 }}>
          Annuler
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color={formState === 'error' ? 'error' : 'primary'}
          disabled={formState === 'loading'}
        >
          Mettre à jour la demande
        </Button>
      </Box>
    </>
  )
}
export default DossierEditView
