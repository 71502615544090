import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { useLocation } from 'react-router-dom'

import type { Admin, Dossier, Option, Updater } from 'model'
import { getCurrentArtisan } from 'api/ArtisanApi'
import { fetchDossier, useDossierParameters } from 'api/DossierApi'
import { ExtendedArtisan } from 'model/Artisan'
import { listCertificatArtisan } from 'api/CertificatArtisanApi'

type ArtisanOpt = Option<ExtendedArtisan>
type DossierOpt = Option<Dossier>
type AdminOpt = Option<Admin>

export type TSessionState = {
  artisan: ArtisanOpt
  dossier: DossierOpt
  admin: AdminOpt
  setArtisan: (fn: Updater<ArtisanOpt>) => void
  setDossier: (fn: Updater<DossierOpt>) => void
  setAdmin: (fn: Updater<AdminOpt>) => void
}

export const SessionContext = createContext<TSessionState | undefined>(undefined)
SessionContext.displayName = 'SessionContext'

type TSessionProviderProps = {
  children: React.ReactNode
}

export const useSession = () => {
  const context = useContext(SessionContext)

  if (!context) {
    throw new Error('useMission must be used within a MissionProvider')
  }

  return context
}

type ProviderState = {
  artisan: ArtisanOpt
  dossier: DossierOpt
  admin: AdminOpt
}

const SessionProvider = ({ children }: TSessionProviderProps) => {
  const [state, setState] = useState<ProviderState>({
    artisan: undefined,
    dossier: undefined,
    admin: undefined,
  })
  const { dossierId, dossierKey } = useDossierParameters()
  const location = useLocation()

  useEffect(() => {
    if (dossierId !== '' && dossierKey !== '') {
      fetchDossier(dossierId, dossierKey).then(dossier =>
        setState(st => ({ ...st, dossier })),
      )
    } else if (location.pathname.indexOf('admin') < 0) {
      getCurrentArtisan().then(artisan => {
        if (artisan) {
          listCertificatArtisan({}).then(certificats => {
            const hasUploadedCertificatAssurance = certificats.filter(c => c.name === 'Assurance').length > 0
            const hasValidCertificatAssurance = certificats.some(
              certificat => certificat.status === 'Valide' && certificat.name === 'Assurance',
            )

            setState(st => ({
              ...st,
              artisan: { ...artisan, hasUploadedCertificatAssurance, hasValidCertificatAssurance },
            }))
          })
        }
      })
    }
  }, [dossierId, dossierKey, location])

  const setArtisan = useCallback(
    (fn: Updater<ArtisanOpt>) => setState(st => ({ ...st, artisan: fn(st.artisan) })),
    [setState],
  )
  const setDossier = useCallback(
    (fn: Updater<DossierOpt>) => setState(st => ({ ...st, dossier: fn(st.dossier) })),
    [setState],
  )
  const setAdmin = useCallback(
    (fn: Updater<AdminOpt>) => setState(st => ({ ...st, admin: fn(st.admin) })),
    [setState],
  )

  const providerValue = useMemo<TSessionState>(
    () => ({ ...state, setArtisan, setDossier, setAdmin }),
    [state, setArtisan, setDossier, setAdmin],
  )

  return (
    <SessionContext.Provider value={providerValue}>{children}</SessionContext.Provider>
  )
}
export default SessionProvider
