import React from 'react'

import SessionProvider from 'stores/SessionProvider'
import Router from 'Router'

const App = () => (
  <SessionProvider>
    <Router />
  </SessionProvider>
)

export default App
