import { ApiError, Contact, ContactStatus } from 'model'
import { GenericResponse, getJson, postJson, putJson } from './utils'

export const createContact = (dossierId: string): Promise<Contact | undefined> =>
  postJson(`/re62/api/contacts`, { dossierId })

export const getContactsArtisan = (): Promise<Contact[]> =>
  getJson('/re62/api/contacts/artisan')

export const getContactsDossier = (dossierId: string, key?: string): Promise<Contact[]> =>
  getJson(`/re62/api/contacts/dossier/${dossierId}`, { key })

export const updateContact = (
  contactId: string,
  dossierKey: string,
  status: ContactStatus,
): Promise<Contact> =>
  putJson(`/re62/api/contacts/${contactId}?key=${dossierKey}`, { status })

export type ContactUsPayload = {
  isArtisan: boolean
  email: string
  sujet: string
  message: string
  recaptcha: string
}

export const contactUs = (
  payload: ContactUsPayload,
): Promise<GenericResponse<boolean> | ApiError> =>
  postJson('/re62/api/contactus', payload)
