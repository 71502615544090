import React, { useState, useCallback, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Box, Breadcrumbs, Button, Alert, Grid, Link } from '@mui/material'
import ReCAPTCHA from 'react-google-recaptcha'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { FormState, isError } from 'model'
import DossierInfosForm from 'forms/DossierInfosForm'
import type { DossierInfos } from 'forms/DossierInfosForm'
import DossierDommagesForm from 'forms/DossierDommagesForm'
import type { DossierDommages } from 'forms/DossierDommagesForm'
import { createDossier } from 'api/DossierApi'
import type { CreateDossierPayload } from 'api/DossierApi'
import { boxSx } from 'ui/ComponentsSx'

const emptyDossierInfos: DossierInfos = {
  nom: '',
  email: '',
  telephone: '',
  codePostal: '62',
}

const emptyDossierDommages: DossierDommages = {
  professions: [],
  description: '',
  budget: 0,
  logementHabitable: true,
  nombrePieces: 0,
  scoreAmplitude: 5,
}

type TState = {
  formState: FormState
  errorMessage?: string
}

const validateDossierInfos = ({ nom, email, codePostal }: DossierInfos): boolean =>
  nom !== '' && email !== '' && email.indexOf('@') > 0 && codePostal !== ''

const CreationDossierPage = () => {
  const [dossierInfos, setDossierInfos] = useState<DossierInfos>(emptyDossierInfos)
  const [dossierDommages, setDossierDommages] =
    useState<DossierDommages>(emptyDossierDommages)
  const [state, seState] = useState<TState>({ formState: 'ready' })
  const { formState, errorMessage } = state

  const setInfosPartial = useCallback(
    (update: Partial<DossierInfos>) => {
      setDossierInfos(prev => ({ ...prev, ...update }))
      seState(st => ({ ...st, formState: 'ready', errorMessage: undefined }))
    },
    [setDossierInfos, seState],
  )
  const setDommagesPartial = useCallback(
    (update: Partial<DossierDommages>) => {
      setDossierDommages(prev => ({ ...prev, ...update }))
      seState(st => ({ ...st, formState: 'ready', errorMessage: undefined }))
    },
    [setDossierDommages, seState],
  )

  const { recaptchaEnabled, recaptchaApiKey } = APP_ENV
  const captchaRef = useRef<ReCAPTCHA>(null)

  const onSubmit = useCallback(() => {
    const recaptcha = captchaRef?.current?.getValue() || ''
    if (!validateDossierInfos(dossierInfos)) {
      seState(st => ({
        ...st,
        formState: 'error',
        errorMessage: 'Veuillez corriger les données saisies',
      }))
    } else if (recaptcha === '' && recaptchaEnabled) {
      seState(st => ({
        ...st,
        formState: 'error',
        errorMessage: 'Veuillez valider le Captcha',
      }))
    } else {
      seState(st => ({ ...st, formState: 'loading', errorMessage: undefined }))
      const payload: CreateDossierPayload = {
        ...dossierInfos,
        ...dossierDommages,
        recaptcha,
      }
      createDossier(payload).then(res => {
        if (isError(res)) {
          seState(st => ({ ...st, formState: 'error', errorMessage: res.message }))
        } else {
          seState(st => ({ ...st, formState: 'success' }))
        }
      })
    }
  }, [dossierInfos, dossierDommages, recaptchaEnabled, seState])

  if (formState === 'success') {
    return (
      <Alert severity="success">
        Votre dossier a été créé mais n'est pas encore actif. Veuillez consulter vos
        emails et cliquer sur le lien pour l'activer
      </Alert>
    )
  } else {
    return (
      <>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginBottom: '25px' }}
        >
          {[
            <Link key="1" component={RouterLink} to="/re62">
              Accueil
            </Link>,
            <Typography key="2" color="text.primary">
              Création demande
            </Typography>,
          ]}
        </Breadcrumbs>

        <Typography variant="h1" component="h1" gutterBottom>
          Je renseigne ma demande de travaux
        </Typography>

        <Typography variant="h2" component="h2" gutterBottom>
          Mes coordonnées
        </Typography>
        <Alert color="info">
          Cette solution est actuellement destinée aux personnes sinistrées du département
          Pas-de-Calais
        </Alert>
        <Box sx={boxSx}>
          <DossierInfosForm value={dossierInfos} onChange={setInfosPartial} />
        </Box>

        <Typography variant="h2" component="h2" gutterBottom>
          Mes dommages
        </Typography>
        <Typography>
          Veuillez renseigner ci-dessous les informations concernant votre demande de
          travaux
        </Typography>
        <Box sx={boxSx}>
          <DossierDommagesForm value={dossierDommages} onChange={setDommagesPartial} />
        </Box>
        {recaptchaEnabled && (
          <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <ReCAPTCHA sitekey={recaptchaApiKey} ref={captchaRef} />
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            {errorMessage && (
              <Alert sx={{ mt: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            <Button
              sx={{ px: 5, minWidth: { xs: '100%', sm: 'auto' } }}
              variant="contained"
              onClick={onSubmit}
              color={formState === 'error' ? 'error' : 'primary'}
              disabled={formState === 'loading'}
            >
              Valider ma demande de travaux
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }
}
export default CreationDossierPage
