import React, { useCallback } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { AppBar, Toolbar, Container, Box, Button, Link, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'

import { useSession } from 'stores/SessionProvider'
import { artisanLogout } from 'api/ArtisanApi'

import { ReactComponent as LogoRE62 } from '../assets/logo-re62.svg'

type TLayoutProps = {
  children: React.ReactNode
}

const Footer = () => (
  <Box
    sx={{
      borderTop: 1,
      mt: 3,
      py: 3,
      borderColor: 'divider',
      backgroundColor: '#102E46',
      padding: '50px 0',
    }}
  >
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{
        mb: 3,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
        marginBottom: 6,
      }}
    >
      <Link
        sx={{ mx: 4, color: '#FFFFFF' }}
        underline="none"
        component={RouterLink}
        to="/re62/mentions-legales"
      >
        Mentions légales
      </Link>
      <Link
        sx={{ mx: 4, color: '#FFFFFF' }}
        underline="none"
        component={RouterLink}
        to="/re62/politique-confidentialite"
      >
        Politique de confidentialité
      </Link>
      <Link
        sx={{ mx: 4, color: '#FFFFFF' }}
        underline="none"
        component={RouterLink}
        to="/re62/contact"
      >
        Nous contacter
      </Link>
    </Typography>

    <Typography variant="body2" align="center" sx={{ color: '#FFFFFF' }}>
      {'Copyright © Keywiiz by '}
      <Link color="inherit" href="https://www.saretec.fr">
        Saretec
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  </Box>
)

const Layout = ({ children }: TLayoutProps) => {
  const { artisan, dossier, setArtisan } = useSession()
  const navigate = useNavigate()

  const logoutUser = useCallback(() => {
    artisanLogout().then(() => {
      setArtisan(() => undefined)
      navigate('/re62/artisan/login')
    })
  }, [setArtisan, navigate])

  const topRightButton =
    dossier !== undefined ? (
      <></>
    ) : artisan !== undefined ? (
      <>
        <Button
          sx={{ mr: 1 }}
          component={RouterLink}
          variant="contained"
          to="/re62/artisan/profile"
        >
          <Typography component="span" variant="button">
            Mon compte
          </Typography>
        </Button>
        <Button onClick={logoutUser}>
          <LogoutIcon />
        </Button>
      </>
    ) : (
      <Button component={RouterLink} variant="contained" to="/re62/artisan/login">
        <Typography component="span" variant="button">
          Accès réparateur
        </Typography>
      </Button>
    )

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
          backgroundColor: '#FFFFFF',
          boxShadow: '0 3px 6px 0 rgba(0,0,0,0.07)',
          height: '80px',
        }}
      >
        <Toolbar sx={{ maxWidth: 'lg', margin: 'auto', width: '100%' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Link
              variant="h6"
              underline="hover"
              component={RouterLink}
              to="/re62"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
            >
              <LogoRE62 />
            </Link>
          </Box>
          <Box>
            <Button
              sx={{ mr: 1, display: { xs: 'none', md: 'inline-flex' } }}
              component={RouterLink}
              to="/re62/contact"
            >
              <Typography component="span" variant="button">
                Nous contacter
              </Typography>
            </Button>
            {topRightButton}
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="lg"
        sx={{ py: 2, minHeight: 'calc(100vh - 210px)', padding: '50px 0' }}
      >
        {children}
      </Container>
      <Footer />
    </>
  )
}
export default Layout
