import React, { useCallback } from 'react'
import { Box, Typography, Button, FormControl, FormGroup } from '@mui/material'

import type { Dossier, DossierStatus, Updater } from 'model'
import { updateDossierStatus } from 'api/DossierApi'
import { boxSx } from 'ui/ComponentsSx'

type TDossierStatusAdminProps = {
  dossier: Dossier
  setDossier: (fn: Updater<Dossier | undefined>) => void
}

const DossierStatusAdmin = (props: TDossierStatusAdminProps) => {
  const { dossier, setDossier } = props

  const setStatus = useCallback(
    (status: DossierStatus) => () =>
      updateDossierStatus(dossier.id, status).then(res => setDossier(() => res)),
    [dossier, setDossier],
  )

  const statuts: DossierStatus[] = ['Attente', 'Ouvert', 'Resolu']
  const buttons = statuts.map(st => {
    const active = st === dossier.status
    return (
      <Button
        key={st}
        variant={active ? 'contained' : 'outlined'}
        sx={{ mr: 2 }}
        onClick={setStatus(st)}
      >
        {st}
      </Button>
    )
  })
  return (
    <>
      <Typography variant="h2">Statut du dossier</Typography>
      <Box sx={boxSx}>
        <FormControl>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>{buttons}</FormGroup>
        </FormControl>
      </Box>
    </>
  )
}

export default DossierStatusAdmin
