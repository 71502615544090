import React, { useState, useCallback, useEffect } from 'react'
import { Typography } from '@mui/material'

import type { Contact, Dossier, FormState } from 'model'
import { getContactsDossier } from 'api/ContactApi'
import { useDossierParameters } from 'api/DossierApi'
import { useSession } from 'stores/SessionProvider'

import DossierRecapView from './DossierRecapView'
import DossierEditView from './DossierEditView'

type TDossierPageProps = {
  dossier: Dossier
}

export type DossierPageView = 'contacts' | 'edit' | 'close'

type TDossierPageState = {
  contacts?: Contact[]
  formState: FormState
  view: DossierPageView
}

const emptyState: TDossierPageState = {
  formState: 'ready',
  view: 'contacts',
}

const DossierPage = ({ dossier }: TDossierPageProps) => {
  const [state, setState] = useState<TDossierPageState>(emptyState)
  const { contacts, view } = state

  const { dossierKey } = useDossierParameters()
  const { setDossier } = useSession()

  const setView = useCallback(
    (newView: DossierPageView) => setState(st => ({ ...st, view: newView })),
    [setState],
  )
  const updateContact = useCallback(
    (updated: Contact) =>
      setState(st => ({
        ...st,
        contacts: (st.contacts || []).map(c => (c.id === updated.id ? updated : c)),
      })),
    [setState],
  )

  useEffect(() => {
    getContactsDossier(dossier.id, dossierKey).then(contacts =>
      setState(st => ({ ...st, contacts })),
    )
  }, [dossier, dossierKey])

  const Loading = () => (
    <Typography variant="h6" component="h3" gutterBottom>
      Récapitulatif de ma demande
    </Typography>
  )

  const content =
    contacts === undefined ? (
      <Loading />
    ) : view === 'edit' ? (
      <DossierEditView dossier={dossier} setDossier={setDossier} setView={setView} />
    ) : (
      <DossierRecapView
        dossier={dossier}
        setView={setView}
        contacts={contacts}
        updateContact={updateContact}
      />
    )

  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        Ma demande de travaux
      </Typography>

      {content}
    </>
  )
}
export default DossierPage
