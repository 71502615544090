import { useSearchParams, useMatch } from 'react-router-dom'

import { Dossier, DossierStatus, Profession } from 'model'
import { postJson, putJson, getJson } from './utils'

export type DossierParameters = {
  dossierId: string
  dossierKey: string
}

export const useDossierParameters = (): DossierParameters => {
  const [searchParams] = useSearchParams()
  const dossierMatch = useMatch('/re62/dossier/:dossierId/*')

  const maybeDossierId = dossierMatch?.params?.dossierId || ''
  const dossierId = maybeDossierId.length === 36 ? maybeDossierId : ''
  const dossierKey = searchParams.get('key') || ''
  return { dossierId, dossierKey }
}

export type CreateDossierPayload = {
  nom: string
  email: string
  telephone?: string
  codePostal: string
  professions: Profession[]
  description: string
  budget?: number
  logementHabitable: boolean
  scoreAmplitude: number
  nombrePieces: number
  recaptcha: string
}

export const createDossier = (payload: CreateDossierPayload): Promise<boolean> =>
  postJson(`/re62/api/dossiers`, payload)

export const fetchDossier = (
  dossierId: string,
  key?: string,
): Promise<Dossier | undefined> =>
  getJson(`/re62/api/dossiers/${dossierId}`, { key }).catch(err => undefined)

export type UpdateDossierPayload = Partial<CreateDossierPayload>

export const updateDossier = (
  dossierId: string,
  dossierKey: string,
  payload: UpdateDossierPayload,
): Promise<Dossier | undefined> =>
  putJson(`/re62/api/dossiers/${dossierId}?key=${dossierKey}`, payload)

export const updateDossierStatus = (
  dossierId: string,
  status: DossierStatus,
): Promise<Dossier | undefined> =>
  putJson(`/re62/api/dossiers/${dossierId}/status`, { status })

export type DossierFilter = {
  createdAfter?: Date
  createdBefore?: Date
  codesPostaux: string[]
  professions: string[]
  status?: DossierStatus
  sortBy: 'createdAt' | 'updatedAt'
  sortOrder: 'ASC' | 'DESC'
  limit?: number
  offset?: number
}
export const getDossiers = (filters: DossierFilter): Promise<Dossier[]> =>
  getJson('/re62/api/dossiers', filters)
