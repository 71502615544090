import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { CertificatArtisanFilter, listCertificatArtisan } from 'api/CertificatArtisanApi'
import { listCertificatArtisanAdmin } from 'api/AdminApi'
import type { CertificatArtisan } from 'model/CertificatArtisan'
import { boxSx } from 'ui/ComponentsSx'
import CertificatItem from './CertificatItem'
import CertificatUploadForm from './CertificatUploadForm'

type CertificatFormProps = {
  artisanId: string
  isAdmin: boolean
}

type State = {
  uploadedCertificatInsurance: CertificatArtisan | null
  uploadedCertificatsInsuranceOther: CertificatArtisan[]
  loadingCertificats: boolean
}

const emptyFilter: CertificatArtisanFilter = {
  limit: 20,
}

const CertificatForm: React.FC<CertificatFormProps> = ({ artisanId, isAdmin }) => {
  const [state, setState] = useState<State>({
    uploadedCertificatInsurance: null,
    uploadedCertificatsInsuranceOther: [],
    loadingCertificats: false,
  })

  const fetchCertificats = useCallback(
    (filter: CertificatArtisanFilter) => {
      setState(prevState => ({ ...prevState, loadingCertificats: true }))

      if (isAdmin) {
        listCertificatArtisanAdmin(artisanId, filter).then(certificats => {
          const certificatInsurance =
            certificats.find(c => c.name === 'Assurance') || null
          const certificatsInsuranceOther = certificats.filter(
            c => c.name === 'AssuranceAutre',
          )
          setState({
            uploadedCertificatInsurance: certificatInsurance,
            uploadedCertificatsInsuranceOther: certificatsInsuranceOther,
            loadingCertificats: false,
          })
        })
      } else {
        listCertificatArtisan(filter).then(certificats => {
          const certificatInsurance =
            certificats.find(c => c.name === 'Assurance') || null
          const certificatsInsuranceOther = certificats.filter(
            c => c.name === 'AssuranceAutre',
          )
          setState({
            uploadedCertificatInsurance: certificatInsurance,
            uploadedCertificatsInsuranceOther: certificatsInsuranceOther,
            loadingCertificats: false,
          })
        })
      }
    },
    [artisanId, isAdmin],
  )

  useEffect(() => {
    fetchCertificats(emptyFilter)
  }, [artisanId, isAdmin, fetchCertificats])

  const refreshCertificats = useCallback(() => {
    fetchCertificats(emptyFilter)
  }, [fetchCertificats])

  return (
    <Box
      sx={{
        ...boxSx,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1em',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '1em',
          width: '100%',
        }}
      >
        <Typography variant="h6" component="span">
          Certificat d'assurance
        </Typography>
        {state.uploadedCertificatInsurance ? (
          <CertificatItem
            certificat={state.uploadedCertificatInsurance}
            refreshCertificats={refreshCertificats}
            isAdmin={isAdmin}
          />
        ) : (
          <CertificatUploadForm
            artisanId={artisanId}
            name="Assurance"
            refreshCertificats={refreshCertificats}
            isAdmin={isAdmin}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '1em',
          width: '100%',
        }}
      >
        <Typography variant="h6" component="span">
          Autres certificats
        </Typography>
        {state.uploadedCertificatsInsuranceOther.map(
          (uploadedCertificat: CertificatArtisan) => (
            <CertificatItem
              key={uploadedCertificat.id}
              certificat={uploadedCertificat}
              refreshCertificats={refreshCertificats}
              isAdmin={isAdmin}
            />
          ),
        )}
        <CertificatUploadForm
          artisanId={artisanId}
          name="AssuranceAutre"
          refreshCertificats={refreshCertificats}
          isAdmin={isAdmin}
        />
      </Box>
    </Box>
  )
}

export default CertificatForm
