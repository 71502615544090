/*
 * Returns a shallow query string - upgrade if more is needed
 */
type SimpleQueryValue = string | number | boolean | Date
type QueryValue = SimpleQueryValue | SimpleQueryValue[] | undefined
export type GenericResponse<T> = { result: T }

export const toQueryString = (obj: Record<string, QueryValue>): string => {
  const toSingleParam = (key: string, value: SimpleQueryValue) =>
    `${key}=${encodeURIComponent(value.toString())}`

  return Object.entries(obj)
    .flatMap(([key, value]) => {
      if (value === undefined || value.toString() === '') {
        return []
      } else if (Array.isArray(value)) {
        return value.map(v => toSingleParam(key, v))
      } else {
        return [toSingleParam(key, value)]
      }
    })
    .join('&')
}

export const getJson = (
  url: string,
  params?: Record<string, QueryValue>,
): Promise<any> => {
  const fullUrl = params === undefined ? url : `${url}?${toQueryString(params)}`
  return fetch(fullUrl).then(response => response.json())
}

export const postJson = (url: string, payload: {}): Promise<any> =>
  fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }).then(response => response.json())

export const postMultipart = (url: string, payload: File): Promise<any> => {
  const formData = new FormData()
  formData.append('file', payload)

  return fetch(url, {
    method: 'POST',
    body: formData,
  }).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  })
}

export const putJson = (url: string, payload: {}): Promise<any> =>
  fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }).then(response => response.json())

export const delJson = (url: string): Promise<any> =>
  fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response.json())
