import React from 'react'
import { Box, Typography } from '@mui/material'

type TRecapItemProps = {
  title: string
  value: React.ReactNode
  inline?: boolean
}

export const RecapItem = ({ title, value, inline }: TRecapItemProps) => (
  <Box
    sx={{
      mr: 2,
      display: 'flex',
      flexDirection: `${inline ? 'row' : 'column'}`,
      gap: 0.5,
      alignItems: `${inline ? 'center' : 'flex-start'}`,
    }}
  >
    <Typography variant="h6" component={inline ? 'span' : 'div'}>
      {title}
    </Typography>

    <Typography variant="body1" component={inline ? 'span' : 'div'}>
      {value}
    </Typography>
  </Box>
)
