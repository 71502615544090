import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Alert, Box, Button, Typography } from '@mui/material'

import { Contact, Dossier } from 'model'
import { ExtendedArtisan } from 'model/Artisan'
import { getDossiers } from 'api/DossierApi'
import type { DossierFilter } from 'api/DossierApi'

import DossiersArtisanList from './DossiersArtisanList'
import ContactsList from './ContactsList'
import { getContactsArtisan } from 'api/ContactApi'

type TDossiersArtisanPageProps = {
  artisan: ExtendedArtisan
}

type DossierTab = 'dispo' | 'contact'
type State = {
  dossiers: Dossier[]
  contacts: Contact[]
  loadingDossiers: boolean
  loadingContacts: boolean
  tab: DossierTab
}

const emptyFilter: DossierFilter = {
  status: 'Ouvert',
  codesPostaux: [],
  professions: [],
  sortBy: 'updatedAt',
  sortOrder: 'DESC',
  limit: 20,
}

const DossiersArtisanPage = ({ artisan }: TDossiersArtisanPageProps) => {
  const [state, setState] = useState<State>({
    tab: 'dispo',
    dossiers: [],
    loadingDossiers: true,
    contacts: [],
    loadingContacts: true,
  })

  const handleTabChange = useCallback(
    (_: unknown, tab: DossierTab) => {
      setState(st => ({ ...st, tab }))
    },
    [setState],
  )
  const addContact = useCallback(
    (added: Contact) =>
      setState(st => {
        return {
          ...st,
          contacts: [...st.contacts, added],
          dossiers: st.dossiers.map(d =>
            d.id === added.dossierId ? { ...d, nbContact: (d.nbContact || 0) + 1 } : d,
          ),
        }
      }),
    [setState],
  )

  useEffect(() => {
    getDossiers({ ...emptyFilter, professions: artisan.professions }).then(dossiers => {
      setState(st => ({ ...st, dossiers, loadingDossiers: false }))
    })
    getContactsArtisan().then(contacts => {
      setState(st => ({ ...st, contacts, loadingContacts: false }))
    })
  }, [setState, artisan])

  const { tab, dossiers, loadingDossiers, contacts, loadingContacts } = state

  const noContactDossiers = useMemo(() => {
    const dossiersWithContact = new Set(contacts.map(c => c.dossierId))
    return dossiers.filter(d => !dossiersWithContact.has(d.id))
  }, [dossiers, contacts])

  const content =
    tab === 'contact' ? (
      <ContactsList artisan={artisan} contacts={contacts} />
    ) : (
      <DossiersArtisanList
        artisan={artisan}
        dossiers={noContactDossiers}
        contacts={contacts}
        addContact={addContact}
      />
    )

  const nbDossiers = noContactDossiers.length > 0 ? ` (${noContactDossiers.length})` : ''
  const nbContacts = contacts.length > 0 ? ` (${contacts.length})` : ''

  if (loadingContacts || loadingDossiers) return <Typography variant="h3">...</Typography>

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', gap: '25px' }}>
        <Button
          variant={state.tab === 'dispo' ? 'contained' : 'text'}
          size="large"
          sx={
            state.tab === 'dispo'
              ? { my: 1, width: 250, height: 37 }
              : {
                  my: 1,
                  width: 250,
                  height: 37,
                  backgroundColor: '#FFFFFF',
                  color: '#102E46',
                  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.07)',
                }
          }
          href="#"
          onClick={e => handleTabChange(e, 'dispo')}
        >
          <Typography component="span" variant="button">
            {`Dossiers disponibles${nbDossiers}`}
          </Typography>
        </Button>
        <Button
          variant={state.tab === 'contact' ? 'contained' : 'text'}
          size="large"
          sx={
            state.tab === 'contact'
              ? { my: 1, width: 220, height: 37 }
              : {
                  my: 1,
                  width: 220,
                  height: 37,
                  backgroundColor: '#FFFFFF',
                  color: '#102E46',
                  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.07)',
                }
          }
          href="#"
          onClick={e => handleTabChange(e, 'contact')}
        >
          <Typography component="span" variant="button">
            {`Mes candidatures${nbContacts}`}
          </Typography>
        </Button>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {!artisan.hasUploadedCertificatAssurance && (
          <Alert severity="error">
            Veuillez importer votre certificat d’assurance de l’année en cours afin de
            compléter votre profil.
            <br />
            Vous pourrez commencer à vous positionner sur les dossiers disponibles dès
            qu'un administrateur aura validé votre certificat d'assurance. Une
            notification vous sera envoyée par e-mail.
          </Alert>
        )}
        {!artisan.hasValidCertificatAssurance && artisan.hasUploadedCertificatAssurance && (
          <Alert severity="info">
            Vous pourrez commencer à vous positionner sur les dossiers disponibles dès
            qu'un administrateur aura validé votre certificat d'assurance. Une
            notification vous sera envoyée par e-mail.
          </Alert>
        )}
        { tab !== 'contact' && (<Alert severity="info">
          Les dossiers listés ici nécessitent <b>au moins</b> l’une des compétences que vous avez renseignées.<br />
          Une compétence est <b>grisée</b> si elle ne fait pas partie de votre liste.
        </Alert>
        )}

        {content}
      </Box>
    </Box>
  )
}
export default DossiersArtisanPage
