export const boxSx = {
  my: 2,
  p: '25px',
  backgroundColor: '#FFFFFF',
  color: '#102E46',
  borderRadius: 6,
  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.07)',
}

export const downloadButtonSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  gap: '0.32125em',
  backgroundColor: '#fff',
  borderRadius: 6,
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}

export const previewBoxSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  gap: '2.5em',
}

export const buttonSx = {
  display: 'flex',
  flexDirection: 'row-reverse',
  gap: '0.3125em',
  padding: '8px 16px',
  backgroundColor: '#fff',
  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.07)',
  borderRadius: 6,
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}

export const wrapperSx = {
  backgroundColor: '#FFFFFF',
  borderRadius: 6,
  color: '#102E46',
  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.07)',
  px: 3,
  py: 2,
  mb: 1,
}
