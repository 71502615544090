export type Profession =
  | 'Peinture'
  | 'Carrelage'
  | 'Parquet'
  | 'Menuiserie'
  | 'Platrerie'
  | 'Plomberie'
  | 'Electricite'
  | 'Miroiterie'
  | 'Charpente'
  | 'Maconnerie'
  | 'Couverture'
  | 'Serrurerie'
  | 'Assechement'
  | 'TravauxPublics'

export const professions: Profession[] = [
  'Peinture',
  'Carrelage',
  'Parquet',
  'Menuiserie',
  'Platrerie',
  'Plomberie',
  'Electricite',
  'Miroiterie',
  'Charpente',
  'Maconnerie',
  'Couverture',
  'Serrurerie',
  'Assechement',
  'TravauxPublics',
]

export const professionLabels: Record<Profession, string> = {
  Peinture: 'Peinture',
  Carrelage: 'Carrelage',
  Parquet: 'Parquet',
  Menuiserie: 'Menuiserie',
  Platrerie: 'Plâtrerie',
  Plomberie: 'Plomberie',
  Electricite: 'Electricité',
  Miroiterie: 'Miroiterie',
  Charpente: 'Charpente',
  Maconnerie: 'Maçonnerie',
  Couverture: 'Couverture',
  Serrurerie: 'Serrurerie',
  Assechement: 'Assèchement',
  TravauxPublics: 'Travaux publics',
}
