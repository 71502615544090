import React, { useMemo } from 'react'

import DossiersArtisanBlock from './DossierArtisanBlock'
import { Contact } from 'model/Contact'
import { ExtendedArtisan } from 'model/Artisan'

type TContactsListProps = {
  artisan: ExtendedArtisan
  contacts: Contact[]
}

const ContactsList = ({ artisan, contacts }: TContactsListProps) => {
  const [open, closed]: [Contact[], Contact[]] = useMemo(() =>
    contacts.reduce(
      ([open, closed]: [Contact[], Contact[]], contact) =>
        contact.dossier.status === 'Ouvert'
        ? [open.concat(contact), closed]
        : [open, closed.concat(contact)],
      [[], []]
    ), [contacts])

  const openContactContent = open.map(contact => (
    <DossiersArtisanBlock
      key={contact.id}
      contact={contact}
      dossier={contact.dossier}
      artisan={artisan}
    />
  ))
  const closedContactContent = closed.map(contact => (
    <DossiersArtisanBlock
      key={contact.id}
      contact={contact}
      dossier={contact.dossier}
      artisan={artisan}
    />
  ))
  return (
    <>
      {openContactContent}
      {closedContactContent}
    </>
  )
}
export default ContactsList
