import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Box, Typography } from '@mui/material'

import { Dossier, DossierStatus } from 'model'
import { getDossiers } from 'api/DossierApi'
import type { DossierFilter } from 'api/DossierApi'
import DossiersAdminBlock from './DossierAdminBlock'
import DossiersAdminFilter from './DossiersAdminFilter'

type State = {
  dossiers: Dossier[]
  loadingDossiers: boolean
  statusFilter: DossierStatus | undefined
}

const emptyFilter: DossierFilter = {
  status: undefined,
  codesPostaux: [],
  professions: [],
  sortBy: 'updatedAt',
  sortOrder: 'DESC',
  limit: 20,
}

const DossiersAdminList = () => {
  const [state, setState] = useState<State>({
    dossiers: [],
    loadingDossiers: true,
    statusFilter: undefined,
  })

  useEffect(() => {
    getDossiers(emptyFilter).then(dossiers => {
      setState(st => ({
        ...st,
        dossiers,
        loadingDossiers: false,
      }))
    })
  }, [state.statusFilter])

  const filteredDossiers = useMemo(() => {
    return state.statusFilter
      ? state.dossiers.filter(d => d.status === state.statusFilter)
      : state.dossiers
  }, [state.dossiers, state.statusFilter])

  const onChangeStatus = useCallback(
    (newStatus: DossierStatus | undefined) => {
      setState(st => ({
        ...st,
        statusFilter: newStatus,
      }))
    },
    [setState],
  )

  const { dossiers, statusFilter, loadingDossiers } = state

  const content = filteredDossiers.map(dossier => (
    <DossiersAdminBlock key={dossier.id} dossier={dossier} />
  ))

  if (loadingDossiers) return <Typography variant="h3">...</Typography>

  return (
    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <DossiersAdminFilter
        dossiers={dossiers}
        statusFilter={statusFilter}
        onChangeStatus={onChangeStatus}
      />
      {content}
    </Box>
  )
}
export default DossiersAdminList
